<script>
export default {
  props: {
    value: [Boolean, Number],
    label: String,

    disabled: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Boolean,
      default: false,
    },

    error_message: {
      type: String,
      default: "",
    },

    description: {
      type: String,
      default: "",
    },

    negative: Boolean,
  },

  computed: {
    isChecked: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },

  methods: {
    toggleCheckbox() {
      this.isChecked = !this.isChecked;
    },
  },
};
</script>

<template>
  <div
    :class="{ 'checkbox-disabled': disabled, 'checkbox-error': error, 'checkbox-negative': negative }"
    class="checkbox"
    @click="toggleCheckbox"
  >
    <div class="checkbox__wrapper">
      <div class="checkbox__box" :class="{ checked: isChecked }">
        <svg
          class="checkbox__box-img"
          v-if="isChecked"
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 4L4 7L9 1"
            stroke="#434F5B"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="checkbox__body">
      <span v-if="label" class="checkbox__label">{{ label }}</span>
      <span v-else class="checkbox__label">
        <slot></slot>
      </span>
      <small v-if="description && !error_message">
        {{ description }}
      </small>
      <small class="checkbox__error-message" v-if="error_message && error">
        {{ error_message }}
      </small>
    </div>
  </div>
</template>

<style scoped lang="scss">
.checkbox {
  display: flex;
  cursor: pointer;
  align-items: flex-start;

  @media (max-width: $mobile-width){
    align-items: center;
  }

  &:hover{
    .checkbox__box{
      opacity: .7;
    }
    .checkbox__label{
      opacity: .7;
    }
  }

  &__wrapper {
    flex-shrink: 0;
    margin-top: 2px;
  }

  &__box {
    width: 16px !important;
    height: 16px;
    margin-right: 8px;
    border: 2px solid var(--grey-60);
    border-radius: 2px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;

    @media (max-width: $mobile-width){
      width: 20px !important;
      height: 20px;
    }

    &.checked {
      border: 2px solid var(--blue-70);
      background-color: var(--blue-10);
    }

    &-img {
      width: 10px;
      height: 8px;
      position: relative;

      @media (max-width: $mobile-width){
        width: 11px !important;
        height: 9 px;
      }
      
      path{
        stroke: var(--blue-70);
      }
    }
  }

  &__label {
    color: var(--grey-70);
    text-align: left;
    transition: .2s;
    @include font-body-2;
  }

  &__body{
    display: flex;
    flex-direction: column;
  }

  &.checkbox-disabled {
    pointer-events: none;

    .checkbox__box.checked {
      border: 2px solid var(--grey-50);

      > svg {
        path {
          stroke: var(--grey-50);
        }
      }
    }

    .checkbox__label {
      color: var(--grey-50);
    }

    .error-message {
      color: var(--red-100);
    }
  }

  &.checkbox-error {
    .checkbox__box {
      border: 1px solid var(--red-100);
      box-shadow: 0 0 0 2px rgba(255, 137, 146, 0.5);
    }
  }

  small {
    color: var(--grey-60);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-top: 4px;
    height: 16px;
  }

  &__error-message{
    color: var(--red-100) !important;
  }

  &-negative{
    .checkbox__box{
      border: 2px solid var(--red-100);
      background-color: #FFEDEF;

      &-img{
        path{
          stroke: var(--red-100);
        }
      }
    }
  }
}
</style>
