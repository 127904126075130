<script>
import { bus } from "../../router/bus";
import { menuList } from "../jsons/menu-list";
export default {
  name: "MainHeaderMobile.vue",

  data: () => ({
    menuIcon: require("../../assets/img/icons/menu.svg"),
    logoIcon: require("../../assets/img/vedexx-logo/logo_light.svg"),
    
    isSetting: 0,
    drawer: false,
    menuItems: menuList,
    rateTitle: null,
    mainPageRefs: {},
    isNotMainPage: false,
    showRateList: false,
  }),
  
  computed: {
    isAuth() {
      return !!this.$_getsetting('is_auth');
    },
    
    isToken() {
      return !!this.$_getsetting('token');
    },
  },
  
  mounted() {
    bus.$on("addMenu", (data) => {
      this.addAdditionalChildren(data)
    });
    
    bus.$on("clear-additional-children", () => {
      this.menuItems[0].children = []
      this.rateTitle = null
    });
    
    this.checkIsSiteAvailable()
    
    if (!this.isAuth && !this.$_getsetting('client_id')) {
      this.$_setsetting('client_id', -99)
    }

    bus.$on('scrollToElements', (ref) => {
      this.mainPageRefs = ref
    })

    bus.$on('getContactRef', (ref) => {
      setTimeout(() => {
        this.mainPageRefs = {...this.mainPageRefs, ...ref}
      })
    })

    bus.$on('getFindRate', (ref) => {
      setTimeout(() => {
        this.mainPageRefs = {...this.mainPageRefs, ...ref}
      })
    })

    bus.$on("showRateList", () => {
      this.showRateList = !this.showRateList
    })

    this.isNotMainPage = this.$route.name !== 'MainPage'
  },
  
  methods:{
    showLoginForm(data) {
      bus.$emit("showloginform", data)
    },
    
    gotoLink(){
      if (this.$route.name !== "MainPage") {
        this.$router.push("/")
      } else {
        bus.$emit('hide_rate')
      }
    },
    
    scrollMeTo(ref, index) {
      this.drawer = false
      const routeMainPage = this.$router.resolve({name: 'MainPage'})
      if (index === 0) {
        bus.$emit('scrollToSection', ref)
      } else {
        if(ref == 'blog'){
          window.open('https://project.vedexx.ru/blog')
        }else{
          if(this.showRateList){
            if(this.mainPageRefs[ref] && ref == 'searchRates' || ref == 'contact'){
              this.mainPageRefs[ref].scrollIntoView({behavior: "smooth", block: 'center', inline: 'center'})
            }else{
              window.open(routeMainPage.href + `#${ref}`)
            }
          } else {
            if(!this.isNotMainPage && this.mainPageRefs[ref]){
              this.mainPageRefs[ref].scrollIntoView({behavior: "smooth", block: 'center', inline: 'center'})
            }else{
              window.open(routeMainPage.href + `#${ref}`)
            }
          }
        }
        
      }
    },
    
    gotoMain(){
      if (this.$route.name !== "MainPage") {
        this.$router.push("/")
      } else {
        bus.$emit('hide_rate')
      }
    },
    
    addAdditionalChildren(data) {
      this.menuItems[0].children = [
        ...data.tabs
      ];
      
      this.rateTitle = data.title
    },
    
    reachYMGoal(name) {
      this.$metrika.reachGoal(name)
    },
    
    checkIsSiteAvailable() {
      this.$postapi(this.$address + this.$setters.getSettingByName.uri, {
        method: this.$setters.getSettingByName.name,
        setting_name: "is_under_construction"
      }).then(data => {
        if (data.error == 0) {
          this.isSetting = JSON.parse(data.message)
          if (this.isSetting.is_under_construction == 1){
            this.$router.push("/ErrorPage")
          }
        }
      });
    },
    
    logOut() {
      this.drawer = false
      this.$_removesetting('token')
      this.$_removesetting('user_id')
      this.$_removesetting('login')
      this.$_removesetting('email')
      this.$_removesetting('is_auth')
      this.$_removesetting('client_id')
      this.$_removesetting('fio')
      this.$_removesetting('clnt_name')
      this.$_removesetting('manager_email')
      this.$_removesetting('is_show_bm_messages')
      this.$_removesetting('manager_name')
      this.$_removesetting('manager_phone')
      for (let key in localStorage) {
        if (key.startsWith('request_data')) {
          this.$_removesetting(key);
        }
      }
      this.$router.push({ path: '/'})
      location.reload()
    },
  },
}
</script>

<template>
  <div
    class="main-header"
  >
    <v-app-bar
      dark
      app
      color="var(--grey-70)"
      flat
      height="64px"
    >
      <div
        class="main-header__logo g-col-xs-1 g-col-sm-1"
        @click="gotoMain"
      >
        <img :src="logoIcon" class="g-col-xs-1 g-col-sm-1">
      </div>
      
      <v-spacer></v-spacer>

      <v-btn
        icon
        @click.stop="drawer = !drawer"
      >
        <img :src="menuIcon" alt="menu Icon"/>
      </v-btn>
    </v-app-bar>
    
    <v-navigation-drawer :style="$route.name == 'MainPage' ? 'z-index: 3004' : ''" touchless clipped color="#FFFFFF" right app v-model="drawer" floating width="90%" height="100%">
      <div class="main-header__navigation">
        <div class="main-header__navigation-close-panel" v-ripple="false" @click.stop="drawer = !drawer">
          <img src="../../assets/img/icons/Аrrow_right.svg"/>
        </div>
        
        <div class="main-header__navigation-title">
          Меню
        </div>
        
        <div>
          <v-list dense v-if="menuItems.length > 0">
            <div
              v-for="(item, index) in menuItems"
              :key="index"
            >
              <v-list-group
                :ripple="false"
                v-if="item.children.length > 0"
                :value="true"
                color="#1C1C1C"
                class="main-header__navigation__menu-group"
              >
                <template v-slot:activator>
                  <v-img class="main-header__navigation-menu__group-icon" :src="item.icon" />
                  <div
                    class="main-header__navigation-menu__group-title"
                    :class="index === 0 ? 'main-header__navigation-menu__group-title-blue' : ''"
                  >{{item.title}}</div>
                </template>
                
                <div class="main-header__navigation-menu-plate" v-if="rateTitle !== null && index === 0" >
                  {{rateTitle}}
                </div>
                
                <v-list-item
                  v-for="(child, i) in item.children"
                  :key="i"
                  :target="child.target"
                  @click="scrollMeTo(child.link, index)"
                  class="main-header__navigation-menu-title"
                >
                  <div>{{child.title}}</div>
            
                </v-list-item>
              </v-list-group>
              
              <v-list-item
                dense
                v-else
                link
                class="main-header__navigation__menu-group"
                :href="item.link"
                :target="item.target"
                @click.prevent="scrollMeTo(item.link)"
              >
                <v-img class="main-header__navigation-menu__group-icon" :src="item.icon" />
                <div class="main-header__navigation-menu__group-title">{{item.title}}</div>
              </v-list-item>
            </div>
          </v-list>
        </div>
        
        <div class="main-header__navigation-login-panel">
          <div>
            <div class="main-header__navigation-login-panel__user" v-if="isAuth">
              <div class="main-header__navigation-login-panel__user-avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 4C13.0609 4 14.0783 4.42143 14.8284 5.17157C15.5786 5.92172 16 6.93913 16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12C10.9391 12 9.92172 11.5786 9.17157 10.8284C8.42143 10.0783 8 9.06087 8 8C8 6.93913 8.42143 5.92172 9.17157 5.17157C9.92172 4.42143 10.9391 4 12 4ZM12 14C16.42 14 20 15.79 20 18V20H4V18C4 15.79 7.58 14 12 14Z" fill="#434F5B"/>
                </svg>
              </div>
              <div class="main-header__navigation-login-panel__user-name">
                {{$_getsetting('login')}}
              </div>
            </div>
          </div>
          
          <div
            v-if="!isAuth"
            class="main-header__navigation-login-panel-link"
            @click="showLoginForm('login'), drawer = false"
          >
            Войти/Регистрация
            <img src="../../assets/img/icons/entrance.svg"/>
          </div>
          
          <div
            v-else
            class="main-header__navigation-login-panel-link"
            @click="logOut()"
          >
            Выйти
            <img
              src="../../assets/img/icons/entrance.svg"
              class="main-header__navigation-login-panel-link-logout"
            />
          </div>
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<style scoped lang="scss">
.main-header {
  display: flex;
  margin: auto;
  padding: 0;
  align-items: center;
  align-content: center;
  
  @media (max-width: 600px) {
    align-items: flex-start;
    align-content: start;
  }
  
  &__logo {
    position: relative;
    float: left;
    cursor: pointer;
    
    > img {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      width: 100%;
      min-width: 121px;
      max-width: 121px;
      height: auto;
      
      @media (max-width: 600px) {
        position: relative;
        width: 100%;
      }
    }
    
    &-mobile-text {
      text-transform: uppercase;
      color: var(--main-bg-color);
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      display: inline;
      align-items: center;
      position: absolute;
      font-size: 25px;
      line-height: 28px;
      margin-left: -110px;
      top: 4px;
      margin-top: 20px;
      letter-spacing: 0.01em;
      
      @media (min-width: 601px) {
        display: block;
      }
    }
  }
  
  &__navigation {
    display: flex;
    height: calc(100% - 135px);
    z-index: 3006;
    min-height: 100%;
    flex-direction: column;
    padding: $size-24 0;
    
    &-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0.01em;
      text-align: left;
      padding: 0 $size-32;
      margin: $size-8 0 $size-16;

      @include font-body-bold-1;
      color: var(--grey-70);
      margin-top: 16px;
    }
    
    &-close-panel {
      background: #FFFFFF;
      width: 100%;
      display: flex;
      padding: 0 $size-32;
      
      > img {
        width: $size-24;
        height: $size-24;
      }
    }
    
    &-menu {
      width: 100%;
      
      &__group {
        padding: $size-4 0;
        margin: $size-4 0 $size-4 $size-48;
        
        &-icon {
          margin: $size-4 $size-8 $size-4 $size-16;
          width: $size-16;
          height: $size-16;
        }
        
        &-title {
          width: 100%;
          font-size: 16px;
          font-weight: 700;
          line-height: 22px;
          letter-spacing: 0.01em;
          text-align: left;
          padding: 0 $size-8;

          @include font-body-bold-2;
          color: var(--grey-70);
          
          &-blue {
            color: var(--blue-70);
          }
        }
      }
      
      &-title {
        height: 32px !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.01em;
        text-align: left;
        color: #737373;
        min-height: 32px !important;
        padding: $size-4 0;
        margin: $size-4 0 $size-4 $size-64;

        @include font-body-2;
        color: var(--grey-60) !important;
      }
      
      &-plate {
        background: var(--grey-5);
        padding: 10px 0 10px 40px;
        margin-left: 24px;
        color: var(--grey-70);
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 0.12px;
      }

      &-link{
        color: var(--grey-60) !important;
      }
    }
    
    &-login-panel {
      background: #EFEFEF;
      width: 100%;
      display: flex;
      padding: 0 $size-32;
      height: 62px;
      bottom: 0;
      //margin-bottom: 40px;
      position: absolute;
      align-items: center;
      justify-content: space-between;
      
      &__user {
        display: flex;
        align-items: center;
        
        &-name {
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: 0.01em;
          text-align: center;
          color: var(--grey-70);

          @include font-description-semibold-0;
        }
        
        &-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: $size-24;
          height: $size-24;
          border-radius: 50%;
          color: #FFFFFF;
          text-align: center;
          margin-right: $size-4;
          font-size: $size-12;
          font-weight: 600;
        }
      }
      
      &-link {
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.01em;
        text-align: right;
        display: flex;
        align-items: center;
        
        @include font-body-3;
        gap: 0 8px;
        
        &-logout {
          transform: rotate(180deg);
        }
      }
    }
  }
  
  &__login-btns {
    float: right;
    position: relative;
    display: flex;
    flex-direction: row;
    
    &-languages {
      margin: 0 20px 0 10px;
      font-weight: 700;
      font-size: 14px;
      
      &-not-active {
        opacity: 0.7;
        font-weight: 500;
      }
      
      &-divider {
        display: inline-block;
        width: 1px;
        margin: 3px 6px 0 6px;
        height: 12px;
        background: white;
      }
    }
    
    &-contact_links {
      color: white;
      font-size: 13px;
      display: flex;
      text-align: end;
      font-weight: 600;
      flex-direction: column;
      line-height: 10px;
      top: 0;
      margin: auto 0;
     }
    
    &-btn {
      font-size: 14px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      text-transform: none;
      
      @media (max-width: 600px) {
        display: none;
      }
    }
    
    &-mobile {
      display: none;
      margin-top: 10px;
      
      @media (max-width: 600px) {
        display: inline-block;
      }
    }
    
    &-divider {
      display: inline-block;
      vertical-align: middle;
      margin: 0 4px 0;
      width: 1.5px;
      height: 30px;
      background: #FFFFFF;
      
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
}

a {
  color: black !important;
  text-decoration: none;
  
  &:hover {
    text-decoration: none;
  }
}
.main-header__navigation-menu-link{
  position: relative;
  z-index: 10;
}
</style>
