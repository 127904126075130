<script>
import { bus } from "../../router/bus";
import ContactForm from "../common/ContactForm";
import { reachYMGoal } from "@/utils/metrika";

export default {
  name: "MainFooter.vue",
  
  components: {
    ContactForm
  },
  
  data: () => ({
    user_email: null,
    message: null,
    subject: null,
    contactInfo: [
      {
        city: "Санкт-Петербург",
        address: "196247, г. Санкт-Петербург, пл. Конституции д. 3,\n" +
          "корпус 2, литер А, ч.п./офис 70Н/1",
        phone: "+7(800)555-09-99",
        email: "spb@vedexx.com",
        goalName: 'SPB',
      },
      {
        city: "Владивосток",
        address: "690001, Г. Владивосток, ул. Светланская, д. 83, оф. 301",
        phone: "",
        email: "vvo@vedexx.com",
        goalName: 'VVO',
      },
      {
        city: "Калининград",
        address: "236006, г. Калининград проспект Ленинский  д. 30А, помещение 7, помещение 8",
        phone: "+7(981)704-43-09",
        email: "kgd@vedexx.com",
        goalName: 'KGD',
      },
      {
        city: "Находка",
        address: "692941, Врангель м-н, Находка, Приморский край Внутрипортовая, 19 офис 3\n",
        phone: "",
        email: "nhd@vedexx.com",
        goalName: 'NHK',
      }
    ],

    socialMediaItems: [
      {
        name: 'telegram',
        img: require('@/assets/img/icons/social-icons/telegram-icon.svg'),
        src: 'https://t.me/vedexx_news'
      },
      {
        name: 'youtube',
        img: require('@/assets/img/icons/social-icons/youtube-icon.svg'),
        src: 'https://www.youtube.com/channel/UCrb52Ufdo6fLfYxrXzdQCsA'
      },
      {
        name: 'rutube',
        img: require('@/assets/img/icons/social-icons/rutube-icon.svg'),
        src: 'https://rutube.ru/channel/42345700/videos/'
      },
      {
        name: 'dzen',
        img: require('@/assets/img/icons/social-icons/dzen-icon.svg'),
        src: 'https://dzen.ru/vedexx'
      },
    ],
  }),
  
  mounted() {
    if (this.$_getsetting('email')) {
      this.user_email = this.$_getsetting('email')
    }

    bus.$emit('getContactRef', (this.$refs))
  },
  
  methods: {
    sendContactForm() {
      this.$postapi(this.$address + this.$getters.setFeedBack.uri, {
        method: this.$getters.setFeedBack.name,
        bcc: '',
        cc: '',
        to: this.$_getsetting('manager_email') ? this.$_getsetting('manager_email') : 'help@vedexx.com',
        subject: this.subject,
        type: 'email',
        body: 'от: ' + this.$_getsetting('clnt_name') + ' почта: <' + this.user_email + '> текст: ' + this.message,
        atachments: [],
      }).then(data => {
        if (data.error == 0) {
          this.clearForm()
          bus.$emit("show_notify", {
            color: "success",
            notifytext: data.message
          });
          this.clearForm()
        }
      });
    },

    addMetrics(name) {
      reachYMGoal(this, name)
    },

    clearForm() {
      this.message = ''
    }
  }
}
</script>

<template>
  <div ref="contact" class="new-footer">
    <div class="new-footer__container">
      <div class="main-footer__icons">
           <a class="main-footer__icon-link" target="_blank" href="https://navigator.sk.ru/orn/1126149#projects">
              <img src="../../assets/img/skolkovo-logo.svg" alt="skolkovo-logo">
           </a>
          </div>
      <div
        class="main-footer"
      >
        <div class="main-footer__section1">
          <div class="main-footer__title">Остались вопросы? Свяжитесь с&nbsp;нами</div>
          <div
            class="main-footer__contacts"
          >
            <div
              class="main-footer__contacts__block"
              v-for="(city, index) in contactInfo"
              :key="index"
            >
              <div class="main-footer__contacts__block-name">{{city.city}}</div>
                <div class="main-footer__contacts-feedback">
                  <div class="main-footer__contacts__block-data" v-if="city.phone !=''">
                    <a class="main-footer__contacts-tel" :href="'tel:' + city.phone" @click="addMetrics(city.goalName + 'phonetap' + (city.goalName === 'SPB' ? 'footer' : ''))">
                      {{city.phone}}
                    </a>
                  </div>

                  <div class="main-footer__contacts__block-data">
                    <a :href="'mailto:' + city.email" @click="addMetrics(city.goalName + 'mailtap')">
                      {{city.email}}
                    </a>
                  </div>
                </div>
              <div class="main-footer__contacts__block-data main-footer__contacts__block-address">{{city.address}}</div>
            </div>
          </div>
          <div class="main-footer__social">
            <a v-for="(item, idx) in socialMediaItems" target="_blank" :key='idx' :href="item.src" class="main-footer__social-item">
              <img :src="item.img" :alt="item.name" :title="item.name">
            </a>
          </div>
        </div>

        <div class="main-footer__section2">
          <ContactForm class="main-footer__form"/>
        </div>
      </div>
  </div>
  </div>
</template>

<style scoped lang="scss">
.main-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  color: #FFF;
  gap: 0 124px;
  position: relative;

  @media (max-width: $tablet-width){
    padding-top: 104px;
    margin: unset;
    gap: 0 55px;
  }

  @media (max-width: $mobile-width){
    flex-direction: column-reverse;
    padding-top: 86px;
  }

  @media (max-width: 400px){
    padding-top: 96px;
  }

  &__section1 {
    flex: 1;

    @media (max-width: $tablet-width) {
      flex: .9;
    }
  }
  
  &__section2 {
    flex: 1 1 .79;

    @media (max-width: $tablet-width) {
      flex: 1;
    }
  }
  
  &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 27px;
    color: #FFF;
    margin-bottom: 32px;

    @include font-headline-1;

    @media (max-width: $tablet-width){
      position: absolute;
      top: 32px;
      margin: unset;
    }

    @media (max-width: $mobile-width){
      @include font-body-bold-1;
      top: 24px;
    }

    @media (max-width: 400px){
      text-align: center;
    }
  }
  
  &__contacts {
    grid-template-columns: repeat(2, minmax(auto, 303px));
    grid-gap: 34px 37px;
    display: grid;
    width: 100%;

    &-tel{
      text-decoration: unset;
    }

    @media (max-width: $tablet-width){
      grid-template-columns: 1fr;
      grid-gap: 32px;
    }

    @media (max-width: $mobile-width){
      margin-top: 24px;
      grid-gap: 24px;
    }
    
    &__block {
      position: relative;
      
      &-name {
        @include font-body-1;
        font-weight: 600;
        font-size: 16px;

        @include font-headline-3;
      }

      &-data {
        color: #FFF;

        @include font-description-1;
      }
    }

    &-feedback{
      margin: 10px 0;
      display: flex;
      align-items: center;
      gap: 0 30px;

      a{
        color: #FFF !important;

        @include font-description-1;

        > i{
          display: none;
        }
      }
    }
  }

  &__icons{
    display: flex;
    align-items: center;
    gap: 0 67px;
    flex-wrap: wrap;

    img{
      max-width: 155px;
      object-fit: contain;
    }

    @media (max-width: $mobile-width){
      gap: 21px;
    }
  }

  &__social{
    margin-top: 32px;
    display: flex;
    gap: 0 24px;
  }
}

a {
  text-decoration: underline;
  color: var(--bg-dark) !important;
  
  &:hover {
    text-decoration: underline;
  }
}
.main-footer__contacts__block-data{
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.new-footer{
  margin-top: 60px;
  background-color: var(--grey-70);

  &__container{
    max-width: 1440px;
    margin: 0 auto;
    padding: 44px 0px;
    width: calc(100% - 64px * 2);

    @media (max-width: $tablet-width){
      padding: 32px;
      width: 100%;
    }

    @media (max-width: $mobile-width){
      padding: 16px;
    }
  }
}


</style>
